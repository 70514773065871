<template>
  <CRow>
    <CCol col="12" xl="12">

      <CButton
        variant="outline" 
        type="submit" 
        color="primary" 
        class='list-new-btn'
        @click="$router.push({path: '/manager/part-categories/create'})"
        v-if="$store.getters.isAdmin"
        >
        <CIcon name="cil-check-circle" />
        New Part Category
      </CButton> 

      <transition name="slide">
        <CCard>
          <CCardHeader>
            <CCardTitle>Part Categories</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                    hover
                    sorter
                    striped
                    table-filter
                    :items="items"
                    :fields="fields"
                    :items-per-page="perPage"
                    :pagination="$options.paginationProps"
                    index-column
            >

              <template #actions="{item, index}">
                <td class="py-2">
                  <CButton
                          color="primary"
                          variant="outline"
                          square
                          size="sm"
                          @click="detailClicked(item, index)"
                  >
                    Details
                  </CButton>
                </td>
              </template>

            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>

import PartCategoryAPI from '/app/src/api/part-category.js'

export default {
  name: 'Part Categories',
  data: () => {
    return {
      partCategoryAPI: new PartCategoryAPI(),
      items: [],
      fields: [
        { key: 'id' },
        { key: 'name' },
        { key: 'product_type' },
        { key: 'image' },
        { key: 'actions' }
      ],
      perPage: 20,
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  created: function () {
    this.getPartCategories()
  },
  methods: {
    getPartCategories: function() {
      this.$store.dispatch('loading')
      let self = this
      this.partCategoryAPI.all()
      .then((part_categories) => {
        this.$store.dispatch('stopLoading')
        self.items = part_categories
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading')
        console.log(error)
      })
    },
    detailClicked (item, index) {
      const itemLink = `/manager/part-categories/${item.id.toString()}`
      this.$router.push({path: itemLink})
    }
  }
}
</script>
